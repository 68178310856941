import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { GuestGuard } from 'app/core/guards/guest.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // KIWID

    // Auth
    {
        path: 'auth',
        canActivate: [GuestGuard],
        canActivateChild: [GuestGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/login',
            },
            {
                path: 'login',
                loadChildren: () => import('app/modules/auth/login/login.component'),
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.component'),
            },
            {
                canActivate: [],
                path: 'reset-password/:token',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.component'),
            },
        ],
    },

    {
        path: 'public',
        data: {
            layout: 'empty',
        },
        component: LayoutComponent,
        children: [
            {
                path: 'invitation/:publicKey',
                loadComponent: () => import('app/modules/public/register/register.component').then((m) => m.RegisterComponent),
            },
            {
                path: 'statement/:publicKey',
                loadComponent: () => import('app/modules/public/statement/public-statement.component').then((m) => m.PublicStatementComponent),
            },
            {
                path: 'payment-link/:publicKey',
                loadComponent: () =>
                    import('app/modules/public/payment-link/public-payment-link.component').then((m) => m.PublicPaymentLinkComponent),
            },
            {
                path: 'receipt/:publicKey',
                loadComponent: () => import('app/modules/public/receipt/public-receipt.component').then((m) => m.PublicReceiptComponent),
            },
            {
                path: 'transaction/:publicKey',
                loadComponent: () => import('app/modules/public/transaction/public-transaction.component').then((m) => m.PublicTransactionComponent),
            },
        ],
    },

    // Logged Routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        data: {
            layout: 'enterprise',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/auth/login',
            },

            // production
            {
                path: 'production',
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/order',
                    },
                    {
                        path: 'order',
                        loadChildren: () => import('app/modules/production/order/order.routes'),
                    },
                ],
            },

            // people
            {
                path: 'people',
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/patient',
                    },
                    {
                        path: 'patient',
                        loadChildren: () => import('app/modules/people/patient/patient.routes'),
                    },
                ],
            },

            // clinic
            {
                path: 'clinic',
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/user-access',
                    },
                    {
                        path: 'user-access',
                        data: {
                            layout: 'empty',
                        },
                        loadChildren: () => import('app/modules/clinic/user-access/user-access.component'),
                    },
                ],
            },

            // error
            {
                path: 'error',
                loadChildren: () => import('app/modules/errors/errors.routes'),
            },
        ],
    },

    { path: '**', redirectTo: 'auth/login' },
];
